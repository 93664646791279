import React from "react"
import CookiesPage from "../../components/pages/CookiesPolicy"
import { graphql } from "gatsby"

const lang = "es"

const CookiesPolicy = ({ data, pageContext }) => {
  const { seoDataJson } = data
  const seoData = {
    url: seoDataJson.url_es,
    titleSeo: seoDataJson.title_es,
    description: seoDataJson.description_es,
    alternateLanguage: seoDataJson.alternateLanguage_es,
    alternateUrl: seoDataJson.alternateUrl_es,
  }

  return <CookiesPage lang={lang} seoData={seoData} pageContext={pageContext} />
}

export const pageQuery = graphql`
  query cookiesEsQuery {
    seoDataJson(name: { eq: "cookies" }) {
      url_es
      title_es
      description_es
      alternateLanguage_es
      alternateUrl_es
    }
  }
`

export default CookiesPolicy
